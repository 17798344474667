import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import CircleList from '../components/CircleList'

import slide1 from '../img/bg-home1.jpg'

export const IndexPageTemplate = ({
  heading,
  subheading,
  mission,
  medicalConditions,
}) => (
  <div className="home-page-wrap">
    <PageHeader bgImage={ slide1 } titleText={ heading } subHeading={ subheading } />

    <section className="section has-background-primary has-text-white">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <h2 className="title has-text-centered has-text-white is-uppercase is-size-3 is-size-4-mobile">{mission.title}</h2>
              <span className="is-size-6-mobile is-size-5">{mission.statement}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section-hp">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content has-text-centered">
            <Link className="button" to="/education">
              View our educational resources
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section has-background-secondary has-text-white">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <h2 className="title has-text-centered has-text-white is-size-3 is-size-4-mobile">{medicalConditions.heading}</h2>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <CircleList data={medicalConditions.diseases} />
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mission: PropTypes.object,
  medicalConditions: PropTypes.shape({
    heading: PropTypes.string,
    diseases: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mission={frontmatter.mission}
        medicalConditions={frontmatter.medicalConditions}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mission {
          title
          statement
        }
        medicalConditions {
          heading
          diseases {
            name
          }
        }
      }
    }
  }
`
